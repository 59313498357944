import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { ChevronUpDownIcon, XMarkIcon } from '@heroicons/react/24/solid';
import type { Query, ReturnedRow } from 'database/bigQueryHelper';
import type { FilterAttributes } from 'database/queryHelper';
import { Fragment, useState } from 'react';

type Props = {
  queryWithResults: Query;
  field: {
    id: string;
    label: string;
  };
  width?: string;
  filterColor?: string;
  handleChange?: (selected: ReturnedRow[]) => void;
  selected?: ReturnedRow[];
  border?: boolean;
  tags?: {
    selected: FilterAttributes[];
    handleChange: (selected: FilterAttributes) => void;
  }
};

export default function FilterMultiSelect({
  queryWithResults,
  field,
  width,
  filterColor,
  handleChange,
  selected,
  tags,
}: Props) {
  const [query, setQuery] = useState('');
  const filtered =
    query === ''
      ? queryWithResults.returnedRows
      : queryWithResults.returnedRows?.filter((data: ReturnedRow) =>
        data[field.label] ? data[field.label]!
          .toString()
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, ''))
          : null
      );
  return (
    <>
      <div className="table-caption">
        <div className={width}>
          <Combobox multiple value={selected} onChange={handleChange}>
            <div className="relative">
              <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                <Combobox.Input
                  placeholder={queryWithResults.title.toString()}
                  className={`w-full rounded-xl py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 ${'border-none'
                    }`}
                  // displayValue={(data) => data.name}
                  onChange={(event) => { setQuery(event.target.value) }}
                />
                {(tags && selected && selected.length > 0) ?
                  <div className='flex flex-wrap'>
                    {tags.selected.filter((t) => selected.find((s) => s[field.label] === t.name)).map((tag, index) => (
                      <div
                        key={index}
                        className={`flex flex-row w-fit shadow-md relative rounded-md px-2 py-0.5 text-xs text-slate-700  mx-1 mb-1 max-w-2xl bg-blue-100`}
                      >
                        <p className="self-center font-semibold text-oca-blue-100">
                          {tag.name}
                        </p>

                        <span className="text-gray-500 font-semibold pl-1 cursor-pointer self-center">
                          <XMarkIcon
                            className="h-3 w-3"
                            aria-hidden="true"
                            onClick={() => { tags.handleChange(tag) }}
                          // onClick={(event) => handleRemoveFilter(filter as any)}
                          />
                        </span>
                      </div>
                    ))}</div> : null}
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </Combobox.Button>
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => { setQuery('') }}
              >
                <Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm scrollbar scrollbar-thumb-gray-700 scrollbar-track-gray-100 scrollbar-thumb-rounded scrollbar-thin">
                  {filtered?.length === 0 && query !== '' ? (
                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                      Aucun résultat
                    </div>
                  ) : (
                    filtered?.map((data, index) => {
                      if (data[field.label]) {
                        return (
                          <Combobox.Option
                            key={index}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? `bg-${filterColor} text-white` : 'text-gray-900'
                              }`
                            }
                            value={data}
                          >
                            {({ selected, active }) => {
                              return (
                                <>
                                  <span
                                    className={`block truncate tooltip relative whitespace-pre-wrap	${selected ? 'font-medium' : 'font-normal'
                                      }`}
                                    title={`${data[field.label]}`}
                                  >
                                    {data[field.label]}
                                    <span className="tooltip-text bg-gray-800 text-white px-2 py-1 rounded-md text-xs absolute bottom-full left-1/2 transform -translate-x-1/2 opacity-0 pointer-events-none transition-opacity duration-300">
                                      This is a tooltip
                                    </span>
                                  </span>
                                  {selected ? (
                                    <span
                                      className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : `text-${filterColor}`
                                        }`}
                                    >
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              );
                            }}
                          </Combobox.Option>
                        );
                      } else {
                        return null;
                      }
                    })
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
        </div>
      </div>
    </>
  );
}
