import type { Query } from 'database/bigQueryHelper';
import type { FilterAttributes } from 'database/queryHelper';
import { createContext, useState } from 'react';

type State = {
  queries: Record<string, Query>;
  addQueries: (newQueries: Record<string, Query>) => void;
  filtersList: FilterAttributes[];
  addFilters: (filtersList: FilterAttributes[]) => void;
  filterCount: number;
  addFilterCount: (count: number) => void;
};

type Props = {
  children: React.ReactNode;
};

export const QueriesFiltersContext = createContext<State>({
  queries: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  addQueries: (_newQueries: Record<string, Query>) => { },
  filtersList: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  addFilters: (_filtersList: FilterAttributes[]) => { },
  filterCount: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  addFilterCount: (_count: number) => { },
});

function QueriesFiltersProvider(props: Props) {
  const [queries, setQueries] = useState({});
  const [filtersList, setFiltersList] = useState<FilterAttributes[]>([]);
  const [filterCount, setFilterCount] = useState(0);

  const addQueries = (newQueries: Record<string, Query>) => {
    setQueries({ ...newQueries });
  };

  const addFilters = (newFilters: FilterAttributes[]) => {
    setFiltersList([...newFilters]);
  };

  const addFilterCount = (count: number) => {
    setFilterCount(count);
  };

  return (
    <QueriesFiltersContext.Provider
      value={{
        queries,
        addQueries,
        filtersList,
        addFilters,
        filterCount,
        addFilterCount,
      }}
    >
      {props.children}
    </QueriesFiltersContext.Provider>
  );
}

export default QueriesFiltersProvider;
