import { Poppins } from '@next/font/google';
import { SessionProvider } from 'next-auth/react';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import '../styles/globals.css';
// import Loader from '../components/loader';

import AppHeader from '@/components/AppHeader';
import QueriesFiltersProvider from '@/components/state/state';
import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

// Analytics reporting
import { Analytics } from '@vercel/analytics/react';

// Set font for all app
const poppins = Poppins({
  subsets: ['latin'],
  weight: ['400'],
});

import type { Session } from 'next-auth';

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: {
  Component: NextPage;
  pageProps: { session: Session };
}) {
  const router = useRouter();

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const start = (url: string) => {
      // No loading screen when going back to homepage (static page)
      if (url !== '/') setLoading(true);
    };
    const end = () => {
      setLoading(false);
    };
    router.events.on('routeChangeStart', start);
    router.events.on('routeChangeComplete', end);
    router.events.on('routeChangeError', end);
    return () => {
      router.events.off('routeChangeStart', start);
      router.events.off('routeChangeComplete', end);
      router.events.off('routeChangeError', end);
    };
  }, [router.events]);
  return (
    <>
      <SessionProvider session={session}>
        <QueriesFiltersProvider>
          <Head>
            <title>Komète ATLAS</title>
            <meta name="description" content="Komète ATLAS par KYU Associés" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="icon" href="/favicon.ico" />
            {/* <script>L_DISABLE_3D = true;</script> */}
          </Head>
          <main className={`${poppins.className} container min-h-screen min-w-full`}>
            <header className="sticky top-0 z-30">
              <AppHeader />
            </header>

            {loading ? (
              <div
                className="relative z-50"
                aria-labelledby="modal-title"
                role="dialog"
                aria-modal="true"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity"></div>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white bg-gradient-to-tr from-white via-neutral-100 to-white p-4 text-left shadow-xl transition-all sm:my-8">
                      <div className="flex flex-col items-center justify-center gap-3">
                        <svg
                          aria-hidden="true"
                          className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <p className="text-sky-900">Chargement en cours...</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : <Component {...pageProps} />}
            {router.pathname !== '/auth/signin' && (
              <footer className="my-3 flex h-16 w-full flex-col items-center justify-center gap-3">
                <div className="flex w-full flex-wrap items-center justify-center gap-1 px-3 text-sm text-sky-900 sm:w-fit sm:rounded-full">
                  <div className="flex flex-wrap items-center">
                    <p className="text-xs sm:text-base">Tableau de bord réalisé par</p>
                    <Image
                      className="h-6 w-16 object-contain sm:h-8 sm:w-24"
                      src={'/logo-komete.svg'}
                      alt="Logo Komète"
                      width={284}
                      height={100}
                    ></Image>
                  </div>
                  <div className="flex flex-wrap items-center gap-0 sm:gap-1">
                    <p className="text-xs sm:text-base">une marque</p>
                    <Link href={'https://www.kyu.fr'} target={'_blank'}>
                      <Image
                        className="ml-1 h-4 w-12 object-contain sm:h-6 sm:w-14"
                        src={'/logo-kyu-dark.png'}
                        alt="Logo Komète"
                        width={339}
                        height={132}
                      ></Image>
                    </Link>
                  </div>
                </div>
                {/* <Link
                  className="flex items-center gap-1"
                  href="mailto:contact.atlas.komete@kyu.fr"
                  target={'_blank'}
                >
                  <p className="text-base sm:text-base">✉️</p>
                  <p className="text-sm sm:text-xs">contact.atlas.komete@kyu.fr</p>
                </Link> */}
              </footer>
            )}

            <Analytics />
          </main>
        </QueriesFiltersProvider>
      </SessionProvider>
    </>
  );
}
